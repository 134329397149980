import { Engage } from './engage.js'
import { loadScript } from './libs/docs.js'
import { socketPath, socketProps } from './libs/socket.js'
import { initTour } from './tour.js'
import { initBanner } from './banner.js'
import {
  loadWidgetFrame,
  initWidget,
  socketConnected,
  socketDisconnected,
  agentsOnline,
  handleNotification
} from './widget.js'

;(async () => {
  const tour = await initTour()
  if (tour) {
    return
  }

  loadScript(socketPath, () => {
    socketProps.socket = window.io('https://ws.engage.so/webpush')
    socketProps.orgSocket = window.io('https://ws.engage.so/inbound')
    socketProps.socket.on('connect', socketConnected)
    socketProps.socket.on('disconnect', socketDisconnected)
    socketProps.socket.on('agents_online', agentsOnline)
    socketProps.socket.on('webpush/notification', handleNotification)
  })
  loadWidgetFrame(async () => {
    Promise.allSettled([
      initWidget(),
      initBanner()
    ])
  })
})()

globalThis.Engage = Engage
