import { $ } from './libs/docs.js'

const createWebPushFrame = (body, o) => {
  body = `<link rel="preconnect" href="https://fonts.googleapis.com"><link rel="preconnect" href="https://fonts.gstatic.com" crossorigin><link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap" rel="stylesheet"><style>* {box-sizing:border-box;padding:0;margin:0;}body{font-family:'Inter',sans-serif;padding:0 1em}h1,h2,h3,p{margin:0;padding:6px 0;color:inherit;}</style>${body}<img src="https://us-central1-suet-170506.cloudfunctions.net/ppx?s=o&d=${o.domain_id}&m=${o.msg_id}">`
  const div = document.createElement('div')
  div.className = 'engage-widget-webia'
  const h = window.innerHeight < 550 ? (window.innerHeight - 50) + 'px' : '550px'
  div.style.cssText = 'max-height:' + h
  // Close
  const closeDiv = document.createElement('div')
  closeDiv.style.cssText = 'padding:0 1em 0.5em 0;text-align:right'
  const close = document.createElement('a')
  close.setAttribute('href', '#')
  close.innerHTML = '<svg width="24" height="24" style="width:24px;height:24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></a>'
  close.addEventListener('click', (e) => {
    e.preventDefault()
    $('.engage-widget-webia').remove()
  })
  closeDiv.appendChild(close)
  div.appendChild(closeDiv)
  // iframe
  const iframe = document.createElement('iframe')
  div.appendChild(iframe)
  div.appendChild(iframe)
  document.body.appendChild(div)
  iframe.contentWindow.document.open()
  iframe.contentWindow.document.write(body)
  iframe.contentWindow.document.close()
  iframe.onload = function () {
    iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px'
  }
}

export {
  createWebPushFrame
}
