import { Engage, userProps } from './engage'
import { addStyle, currentPath, loadScript } from './libs/docs'
import {
  setTour,
  logoBtnClicked,
  addTourFrame,
  startTour,
  tourProps
} from './components/tours'
import style from './assets/styles-tour.js'

const initTour = async () => {
  let tour = null
  let tourOn = false
  let preview = false

  // Creating a tour?
  const urlParams = new URLSearchParams(window.location.search)
  const tt = urlParams.get('engage_tour')
  if (tt) {
    urlParams.delete('engage_tour')
    preview = urlParams.get('engage_tour_preview')
    if (preview) {
      urlParams.delete('engage_tour_preview')
    }
    window.history.replaceState({}, document.title, window.location.pathname + '?' + urlParams.toString())
    try {
      const data = await Engage.request('/campaigns/tours/editor?token=' + tt)
      if (data.id) {
        tourOn = true
        tourProps.tourAuthorMode = true
        tourProps.id = data.id
        data.token = tt
        tour = setTour(data)
      }
    } catch (e) {}
  } else {
    // Is there an existing tour?
    tour = window.sessionStorage.getItem('engage_tour')
    if (tour) {
      try {
        tour = JSON.parse(tour)
        if (tour.ts && (new Date() - new Date(tour.ts)) > 1000 * 60 * 60 * 6) {
          // 6 hours expiry
          window.sessionStorage.removeItem('engage_tour')
          window.sessionStorage.removeItem('engage_tour_action')
          window.sessionStorage.removeItem('engage_tour_next')
          tour = null
        } else {
          tourOn = true
          tourProps.id = tour.id
          if (tour.token) {
            tourProps.tourAuthorMode = true
          }
        }
      } catch (e) {}
    }
  }

  if (!tourOn) {
    // Viewing tour?
    try {
      const data = await Engage.request('/campaigns/tours/active?uid=' + userProps.uid + '&path=' + currentPath)
      if (data.id) {
        tourProps.id = data.id
        tourOn = true
        tour = setTour(data)
      }
    } catch (e) {}
  }

  if (tourOn) {
    if (document.getElementById('engage_tour_frame')) {
      return
    }
    addStyle(style)
    tourProps.tourContainerDiv = document.createElement('div')
    tourProps.tourContainerDiv.className = 'engage-tour-container'
    if (tourProps.tourAuthorMode) {
      tourProps.tourIframe = document.createElement('iframe')
      tourProps.tourIframe.src = 'https://d2969mkc0xw38n.cloudfront.net/widget-tour/index.html'
      tourProps.tourIframe.id = 'engage_tour_frame'
      tourProps.tourIframe.className = 'et-minimized'
      tourProps.tourContainerDiv.appendChild(tourProps.tourIframe)
    }

    tourProps.modalDiv = document.createElement('div')
    tourProps.modalDiv.className = 'engage-tour-container_modal et-hidden'
    tourProps.tourContainerDiv.appendChild(tourProps.modalDiv)

    if (tourProps.tourAuthorMode) {
      const iconDiv = document.createElement('div')
      const button = document.createElement('button')
      button.className = 'engage-tour-logo-btn'
      button.innerHTML = '<svg width="32" height="32" style="width:32px;height:32px" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M74.7238 39.6656C74.7238 39.0201 74.7111 38.3746 74.6536 37.7485C73.575 23.3606 60.3891 12 44.2928 12C27.4754 12 13.8427 24.3933 13.8427 39.672C13.8427 51.407 21.8781 61.4378 33.2259 65.4657C36.5574 66.6534 38.3956 70.2939 37.3935 73.7214L36.7298 76C44.6183 71.7269 53.0366 67.8604 60.4465 62.7998C66.6119 58.6041 72.5155 52.4268 74.175 44.8617H46.1501C43.9546 49.4447 38.9253 52.6592 33.0727 52.6592C26.7797 52.6592 21.4249 48.9477 19.5421 43.8031C19.1911 42.8414 18.9486 41.8279 18.8464 40.7822H74.7111C74.7238 40.395 74.7238 40.0399 74.7238 39.6656ZM19.1145 36.9739C20.4739 31.0999 26.1989 26.6977 33.0727 26.6977C39.9465 26.6977 45.665 31.0935 47.0436 36.9739H19.1145Z" fill="#fff"/></svg>'
      button.addEventListener('click', logoBtnClicked)
      iconDiv.appendChild(button)
      tourProps.tourContainerDiv.appendChild(iconDiv)
    }

    document.body.appendChild(tourProps.tourContainerDiv)

    if (tourProps.tourAuthorMode) {
      loadScript('//d2969mkc0xw38n.cloudfront.net/next/selector/selector.js', addTourFrame({
        tour,
        previewMode: preview
      }))
      // Nothing else matters
      return true
    }

    startTour({ previewMode: preview })
  }

  return false
}

export {
  initTour
}
