import { Engage, userProps, user, identified, clientId } from '../engage.js'
import { $, docTitle } from '../libs/docs.js'
import { socketProps } from '../libs/socket.js'

const widgetProps = {
  badge: 0,
  uidSet: identified
}

const resize = () => {
  const w = window.innerWidth < 360 ? '100%' : '360px'
  widgetProps.h = window.innerHeight < 550 ? (window.innerHeight - 20) + 'px' : '550px'
  widgetProps.widgetIframe.style.cssText = 'width:' + w + ';height:' + widgetProps.h

  widgetProps.framePort.postMessage({
    ppties: { h: widgetProps.h }, type: 'set'
  })
}
const updateButtonBadge = () => {
  const el = widgetProps.containerDiv.querySelector('.widgetProps.badge')
  if (!el) {
    return
  }
  el.innerText = widgetProps.badge
  if (widgetProps.badge === 0 && !el.classList.contains('dn')) {
    el.classList.add('dn')
    return
  }
  if (widgetProps.badge && el.classList.contains('dn')) {
    el.classList.remove('dn')
  }
}
const joinRoom = () => {
  if (socketProps.socket && widgetProps.account && widgetProps.account.id) {
    socketProps.socket.emit('room', widgetProps.account.id)
    socketProps.socket.emit('room', widgetProps.account.id + ':' + userProps.uid)
  }
}
const toggleWidget = () => {
  // Once window is visible, reset widgetProps.badge and title
  if (!widgetProps.containerDiv.classList.contains('opened')) {
    document.title = docTitle
    widgetProps.badge = 0
    updateButtonBadge()
    // Clear welcome
    const wd = $('.engage-widget-container .welcome')
    if (wd) {
      wd.remove()
    }
  }
  widgetProps.containerDiv.classList.toggle('opened')
}
const handlePortMessages = (e) => {
  const data = e.data
  if (data.action === 'close') {
    widgetProps.containerDiv.classList.toggle('opened')
    return
  }
  if (data.action === 'maximize') {
    const w = window.innerWidth < 520 ? '100%' : '520px'
    widgetProps.h = window.innerHeight < 650 ? (window.innerHeight - 20) + 'px' : '650px'
    widgetProps.widgetIframe.style.cssText = 'width:' + w + ';height:' + widgetProps.h

    widgetProps.framePort.postMessage({
      ppties: { h: widgetProps.h },
      type: 'set'
    })
    return
  }
  if (data.action === 'minimize') {
    resize()
    return
  }
  if (data.action === 'set_user') {
    user.name = data.user.name
    user.email = data.user.email
    window.localStorage.setItem('engage_user', JSON.stringify({
      uid: userProps.uid,
      name: data.user.name,
      email: data.user.email
    }))
    return
  }
  if (data.action === 'send_chat') {
    const params = {
      body: data.body,
      uid: user.uid,
      cid: clientId
    }
    if (user.name) {
      params.name = user.name
    }
    if (user.email) {
      params.email = user.email.trim().toLowerCase()
    }
    Engage.request('/messages/chat', params, 'POST')
      .then(body => {
        // Set uid
        if (!body.uid) {
          return
        }
        widgetProps.uidSet = true
        if (body.uid === user.uid) {
          return
        }
        // If user sent anon id but exist on server w/ diff id
        const uid = body.uid
        // Join new room
        socketProps.socket.emit('room', widgetProps.account.id + ':' + uid)
        user.uid = uid
        widgetProps.framePort.postMessage({ user, type: 'set' })
        window.localStorage.setItem('engage_user', JSON.stringify(user))
      })
    return
  }
  if (data.action === 'ack') {
    // Mark message as read
    Engage.request('/messages/chat/ack', { id: data.id }, 'POST')
      .then(() => {})
    return
  }
  if (data.action === 'typing') {
    socketProps.orgSocket.emit(data.action, {
      org: widgetProps.account.id,
      parent_id: data.parent_id,
      user: user.uid
    })
    return
  }
  if (data.action === 'stopped-typing') {
    socketProps.orgSocket.emit(data.action, {
      org: widgetProps.account.id,
      parent_id: data.parent_id,
      user: user.uid
    })
  }
}

export {
  widgetProps,
  toggleWidget,
  joinRoom,
  resize,
  updateButtonBadge,
  handlePortMessages
}
