const currentPath = window.location.href.startsWith('http://') || window.location.href.startsWith('https://') ? window.location.href : window.location.protocol + '//' + window.location.href
const docTitle = document.title

const $ = (target) => {
  const els = document.querySelectorAll(target)
  // return els.length ? (els.length > 1 ? els : els[0]) : null
  return els.length ? els[0] : null
}

const addStyle = (styles) => {
  const styleSheet = document.createElement('style')
  styleSheet.type = 'text/css'
  document.getElementsByTagName('head')[0].appendChild(styleSheet)
  if (styleSheet.styleSheet) {
    styleSheet.styleSheet.cssText = styles // IE
  } else {
    styleSheet.innerHTML = styles // Others
  }
}
const loadScript = (url, callback) => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = url

  script.onreadystatechange = callback
  script.onload = callback

  document.getElementsByTagName('head').item(0).appendChild(script)
}

const getBgColor = (element) => {
  const computedStyle = window.getComputedStyle(element)
  const color = computedStyle.backgroundColor
  if ((color !== 'rgba(0, 0, 0, 0)') && (color !== 'transparent')) {
    return color
  }
  if (element === document.body) {
    return '#fff'
  }

  return getBgColor(element.parentElement)
}

export {
  $,
  docTitle,
  currentPath,
  addStyle,
  getBgColor,
  loadScript
}
