import { Engage, userProps } from './engage.js'
import { currentPath } from './libs/docs.js'

const initBanner = async () => {
  try {
    const data = await Engage.request('/campaigns/banners/active?uid=' + userProps.uid + '&path=' + currentPath)
    const banner = document.createElement('div')
    banner.className = 'engage-banner'
    if (data) {
      if (data.style === 'inline') {
        let style = 'z-index:99999;position:fixed;padding:1em;width:100%;text-align:center;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;box-sizing:border-box;'
        style += data.position === 'top' ? 'top:0' : 'bottom:0'
        style += ';background-color:'
        style += data.bgcolor ? data.bgcolor : '#10273c'
        style += ';color:'
        style += data.color ? data.color : '#fff'

        banner.style.cssText = style
        const cs = window.getComputedStyle(document.body)
        const bkPosition = cs.getPropertyValue('position')
        const bkMarginTop = cs.getPropertyValue('margin-top')
        if (bkMarginTop) {
          document.body.style.marginTop = (+(bkPosition.replace(/[^0-9]/g, '')) + 53) + 'px'
        }
        document.body.style.position = 'relative'
        if (data.dismiss) {
          const link = document.createElement('a')
          link.href = '#'
          link.innerHTML = '<svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" style="stroke:currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>'
          link.addEventListener('click', async (e) => {
            e.preventDefault()
            link.closest('.engage-banner').remove()
            document.body.style.marginTop = bkMarginTop
            document.body.style.position = bkPosition
            // Track dismiss
            await Engage.request('/campaigns/banners/' + data.id + '/d?uid=' + userProps.uid)
          })
          const fr = document.createElement('div')
          fr.style.cssText = 'float:right'
          fr.appendChild(link)
          banner.appendChild(fr)
        }
        const el = document.createElement('span')
        el.innerHTML = data.body
        banner.appendChild(el)
      } else if (data.style === 'float') {
        let style = 'z-index:99999;position:fixed;border-radius:.5rem;padding:1em;max-width:50%;text-align:center;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;'
        if (data.position === 'tr') {
          style += 'top: 10px; right: 10px'
        } else if (data.position === 'tl') {
          style += 'top: 10px; left: 10px'
        } else if (data.position === 'bl') {
          style += 'bottom: 10px; left: 10px'
        } else if (data.position === 'br') {
          style += 'bottom: 10px; right: 10px'
        }
        style += ';background-color:'
        style += data.bgcolor ? data.bgcolor : '#10273c'
        style += ';color:'
        style += data.color ? data.color : '#fff'

        banner.style.cssText = style
        banner.innerHTML = data.body
        if (data.dismiss) {
          const link = document.createElement('a')
          link.href = '#'
          link.style.cssText = 'margin-left:10px'
          link.innerHTML = '<svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" style="stroke:currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>'
          link.addEventListener('click', async (e) => {
            e.preventDefault()
            link.closest('.engage-banner').remove()
            // track dismiss
            await Engage.request('/campaigns/banners/' + data.id + '/d?uid=' + userProps.uid)
          })
          banner.appendChild(link)
        }
      }
      // Todo: track display (unique)
      document.body.insertBefore(banner, document.body.firstChild)
    }
  } catch (e) {
    console.warn(e)
  }
}

export {
  initBanner
}
