import { Engage, userProps, user, clientId, identified } from './engage.js'
import {
  joinRoom,
  resize,
  toggleWidget,
  handlePortMessages,
  updateButtonBadge,
  widgetProps
} from './components/widget.js'
import { $, addStyle } from './libs/docs.js'
import { socketProps } from './libs/socket.js'
import { createWebPushFrame } from './webia.js'

let lastMsgId
const sound = new window.Audio('https://d2969mkc0xw38n.cloudfront.net/cdn/misc/pop.mp3')

const initWidget = async () => {
  try {
    widgetProps.account = await Engage.request('/account')
    // Customize chat
    if (!userProps.no_chat && widgetProps.account.features && widgetProps.account.features.chat) {
      widgetProps.containerDiv.classList.remove('no-chat')
      if (widgetProps.account.features.chat.color) {
        // Custom color. Update stylesheet
        const style = `.engage-widget-container .chat-btn {
          background-color: ${widgetProps.account.features.chat.color}
        }
        .engage-widget-container .chat-btn:hover {
        background-color: ${widgetProps.account.features.chat.color}e6;
        }`
        addStyle(style)
      }
      if (widgetProps.account.features.chat.welcome) {
        const wDiv = document.createElement('div')
        wDiv.addEventListener('click', () => {
          toggleWidget()
        })
        wDiv.innerText = widgetProps.account.features.chat.welcome

        const closeDiv = document.createElement('div')
        closeDiv.style.cssText = 'position:absolute;right:5px;top:5px'
        const close = document.createElement('a')
        close.setAttribute('href', '#')
        close.innerHTML = '<svg width="18" height="18" style="width:18px;height:18px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></a>'
        close.addEventListener('click', (e) => {
          e.preventDefault()
          $('.engage-widget-container .welcome').remove()
        })
        closeDiv.appendChild(close)

        const msgDiv = document.createElement('div')
        msgDiv.className = 'welcome'
        msgDiv.appendChild(closeDiv)
        msgDiv.appendChild(wDiv)
        widgetProps.containerDiv.appendChild(msgDiv)
      }
    }
  } catch (e) {
    return console.warn(e)
  }

  joinRoom()

  // Register port with widget
  widgetProps.widgetIframe.contentWindow.postMessage(':)', '*', [widgetProps.channel.port2])
  // Set height
  widgetProps.framePort.postMessage({
    ppties: { h: widgetProps.h },
    type: 'set'
  })
  // Send uid
  widgetProps.framePort.postMessage({ user, type: 'set' })
  widgetProps.framePort.postMessage({ identified, type: 'set' })
  // Send account
  if (widgetProps.account) {
    widgetProps.framePort.postMessage({
      account: widgetProps.account,
      type: 'set'
    })
  }

  // Get pending chat messages
  try {
    const chat = await Engage.request('/messages/chat/open?uid=' + userProps.uid)
    if (chat && chat.messages && chat.messages.length) {
      widgetProps.framePort.postMessage({ chat, type: 'chat' })
      lastMsgId = chat.messages[chat.messages.length - 1].id
      // How many unread messages?
      for (const m of chat.messages) {
        if (!m.outbound && !m.read) {
          widgetProps.badge++
        }
      }
      if (widgetProps.badge) {
        // Badge
        updateButtonBadge()
      }
    }
  } catch (e) {
    console.warn(e)
  }

  // 2. Pending web messages?
  try {
    const msg = await Engage.request('/messages/push/latest?uid=' + userProps.uid)
    if (msg && msg.body) {
      const opened = widgetProps.containerDiv.classList.contains('opened')
      if (!opened) {
        createWebPushFrame(msg.body, msg)
      }
    }
  } catch (e) {
    console.warn(e)
  }

  // todo: What about conversations?
  // Get any data-help-id
  const helpLinks = document.querySelectorAll('[data-help-id]')
  for (const el of helpLinks) {
    el.addEventListener('click', function (e) {
      e.preventDefault()
      const help = {
        id: this.dataset.helpId
      }
      if (this.dataset.helpType) {
        help.type = this.dataset.helpType
      }
      if (this.dataset.helpLocale) {
        help.locale = this.dataset.helpLocale
      }
      widgetProps.framePort.postMessage({ help, type: 'action' })
      if (!widgetProps.containerDiv.classList.contains('opened')) {
        widgetProps.containerDiv.classList.add('opened')
      }
    })
  }

  // Helpers
  // Button click
  Engage.openChat = function () {
    toggleWidget()
  }
  Engage.openHelp = function (id, helpType, locale) {
    const help = {
      id
    }
    if (helpType) {
      help.type = helpType
    }
    if (locale) {
      help.locale = locale
    }
    widgetProps.framePort.postMessage({ help, type: 'action' })
    if (!widgetProps.containerDiv.classList.contains('opened')) {
      widgetProps.containerDiv.classList.add('opened')
    }
  }

  // Override identify
  const _identify = Engage.identify
  Engage.identify = function (u) {
    if (u && u.id) {
      userProps.uid = u.id
      user.uid = u.id
      joinRoom()
      _identify(u)
    }
  }

  // Handle resize
  window.addEventListener('resize', () => {
    resize()
  })
}
const loadWidgetFrame = (onLoad) => {
  if (document.getElementById('engage_wp_frame')) {
    return
  }
  if (!widgetProps.channel) {
    widgetProps.channel = new MessageChannel()
  }
  if (!widgetProps.framePort) {
    widgetProps.framePort = widgetProps.channel.port1
    widgetProps.framePort.onmessage = handlePortMessages
  }
  if (!widgetProps.containerDiv) {
    widgetProps.containerDiv = document.createElement('div')
  }
  if (!widgetProps.widgetIframe) {
    widgetProps.widgetIframe = document.createElement('iframe')
  }

  const styles = `
.engage-widget-container iframe {
display: none;
}
.engage-widget-container.no-chat > div {
display: none;
}
.engage-widget-container.opened iframe {
display: block;
}
.engage-widget-container.opened .chat-btn {
display: none;
}
.engage-widget-container .chat-btn {
position: fixed;
bottom: 20px;
right: 20px;
border: 0;
cursor: pointer;
width: 60px;
height: 60px;
border-radius: 100%;
background-color: #0d74ed
}
.engage-widget-container .welcome {
animation: show 600ms 100ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;
opacity: 0;
position: fixed;
bottom: 90px;
font: 14px/1.5 Helvetica,Arial,sans-serif;;
color: #222;
border-radius: 8px;
right: 20px;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
cursor: pointer;
max-width: 300px;
padding: 1.2rem;
background-color: #fff
}
.engage-widget-container .welcome a svg {
color:#444
}
.engage-widget-container .welcome a:hover svg {
color:#111
}
@keyframes show {
  100% {
    opacity: 1;
    transform: none;
  }
}
.engage-widget-container .badge {
position: absolute;
top: 0;
min-width: 24px;
right: 0;
background-color: rgba(236, 56, 65, 0.9);
padding: 5px;
color: #fff;
border-radius: 50%;
}
.engage-widget-container .badge.dn {
display: none
}
.engage-widget-container .chat-btn svg {
width: 28px;
height: 28px;
display: inline;
}
.engage-widget-container .chat-btn:hover {
background-color: #0d74ede6;
}
.engage-banner p {
margin: 0;
padding: 0;
display: inline;
}
.engage-banner a {
color: inherit;
text-decoration: underline;
}
.engage-banner a:hover {
opacity: 0.7
}
.engage-banner a:hover svg {
stroke: #fff
}
.engage-ww.component-text p {
margin: 0;
padding: 6px 0
}
.engage-widget-webia iframe {
border: 0;
width: 100%;
}
.engage-widget-webia a {
color: #aaa
}
.engage-widget-webia a:hover {
color: #444
}
.engage-widget-webia {
padding: 1em 0;
background-color:#fff;
position: fixed;
width: 360px;
overflow-y: scroll;
max-height: 550px;
bottom: 10px;
right: 10px;
z-index: 10000;
border-radius: 5px;
box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
}
.engage-widget-container iframe {
border: 0;
background-color:#fff;
position: fixed;
width: 360px;
height: 550px;
bottom: 10px;
right: 10px;
z-index: 10000;
border-radius: 5px;
box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
transition: width .5s, height .5s;
}
`
  addStyle(styles)

  widgetProps.containerDiv.className = 'engage-widget-container no-chat'
  widgetProps.widgetIframe.src = 'https://d2969mkc0xw38n.cloudfront.net/widget_v2/widget.html'
  widgetProps.widgetIframe.id = 'engage_wp_frame'
  const w = window.innerWidth < 360 ? '100%' : '360px'
  widgetProps.h = window.innerHeight < 550 ? (window.innerHeight - 20) + 'px' : '550px'
  widgetProps.widgetIframe.style.cssText = 'width:' + w + ';height:' + widgetProps.h
  widgetProps.containerDiv.appendChild(widgetProps.widgetIframe)
  const iconDiv = document.createElement('div')
  const button = document.createElement('button')
  button.className = 'chat-btn'
  button.innerHTML = '<span class="badge dn"></span><svg xmlns="http://www.w3.org/2000/svg" width="192" height="192" fill="#ffffff" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M216,48H40A16,16,0,0,0,24,64V222.8a15.7,15.7,0,0,0,9.3,14.5,16,16,0,0,0,17-2.2L82,208.4l134-.4a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48ZM160,152H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Zm0-32H96a8,8,0,0,1,0-16h64a8,8,0,0,1,0,16Z"></path></svg>'
  button.addEventListener('click', () => {
    toggleWidget()
  })
  iconDiv.appendChild(button)
  widgetProps.containerDiv.appendChild(iconDiv)
  document.body.appendChild(widgetProps.containerDiv)
  widgetProps.widgetIframe.addEventListener('load', onLoad)
}

// Socket handlers
const agentsOnline = (count) => {
  setTimeout(function checkfp () {
    if (widgetProps.framePort) {
      widgetProps.framePort.postMessage({ agents_online: count, type: 'set' })
    } else {
      setTimeout(checkfp, 1000)
    }
  }, 0)
}
const handleNotification = (data) => {
  if (!data.type) {
    return
  }
  // Typing
  if (['typing', 'stopped-typing'].includes(data.type)) {
    widgetProps.framePort.postMessage({ type: data.type })
    return
  }
  const opened = widgetProps.containerDiv.classList.contains('opened')
  if (data.type === 'chat') {
    /*
    - If closed
      - If chat
        - Make sound
        - Show notification and badge
      - If message
        - Popup simple overlay
    - If open
      - Chat/Message
        - If open, just update
        - If not, notification in title bar or somewhere
    */
    if (data.upgrade_uid && !widgetProps.uidSet) {
      // Temp socket data.
      if (data.uid !== userProps.uid) {
        widgetProps.uidSet = true
        userProps.uid = data.uid
        // Upgrade
        // Join new room
        socketProps.socket.emit('room', widgetProps.account.id + ':' + userProps.uid)
        user.uid = userProps.uid
        if (data.from && data.from.email) {
          user.email = data.from.email
        }
        widgetProps.framePort.postMessage({ user, type: 'set' })
        window.localStorage.setItem('engage_user', JSON.stringify(user))
      }
    }
    // Sent from same client
    if (data.cid === clientId) {
      return
    }
    // Only update chat if uid is still same
    if (data.uid === userProps.uid) {
      // Notification if message not sent from you
      if (!data.outbound && (document.visibilityState === 'hidden' || !opened)) {
        try {
          sound.play()
        } catch (e) {}
        if (!opened) {
          document.title = '* New message'
          widgetProps.badge++
          updateButtonBadge()
        }
      }
      // Send message to iframe and set view to chat already
      widgetProps.framePort.postMessage({ data, type: 'new_message' })
      lastMsgId = data.id
    }
  }
  // Web inapp notifications
  if (data.type === 'web' && !opened) {
    // Only show if not opened
    createWebPushFrame(data.body, data)
  }
}
const socketConnected = async () => {
  if (!userProps.uid) {
    return
  }

  joinRoom()
  // Load pending messages here
  if (socketProps.wasDisconnected) {
    // Load new messages here
    try {
      const chat = await Engage.request('/messages/chat/open?uid=' + userProps.uid + '&since=' + lastMsgId)
      if (chat && chat.messages && chat.messages.length) {
        lastMsgId = chat.messages[chat.messages.length - 1].id
        const opened = widgetProps.containerDiv.classList.contains('opened')
        // Hidden or not?
        if (document.visibilityState === 'hidden' || !opened) {
          try {
            sound.play()
          } catch (e) {}
          if (!opened) {
            document.title = '* New message'
            widgetProps.badge += chat.messages.length
            updateButtonBadge()
          }
        }
        for (const m of chat.messages) {
          widgetProps.framePort.postMessage({ data: m, type: 'new_message' })
        }
      }
    } catch (e) {
      console.warn(e)
    }
    socketProps.wasDisconnected = false
  }
}
const socketDisconnected = async () => {
  socketProps.wasDisconnected = true
  // Attempt reconnecting
  let delay = 2000
  setTimeout(function tick () {
    if (!socketProps.wasDisconnected) {
      return
    }
    socketProps.socket.connect()
    delay *= 1.5
    setTimeout(tick, delay)
  }, delay)
}

export {
  agentsOnline,
  handleNotification,
  socketConnected,
  socketDisconnected,
  initWidget,
  loadWidgetFrame
}
