import * as Engage from '@engage_so/core'

const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

let key // API Key. Need for requests later
const userProps = {}
const clientId = uuidv4()

// Run Engage queues
const queue = window.engage && window.engage.queue ? window.engage.queue.slice(0) : []
for (const q of queue) {
  if (q[0] === 'identify' && q[1] && q[1].id) {
    userProps.uid = q[1].id
  } else if (!key && q[0] === 'init' && q[1]) {
    key = (typeof q[1] === 'object' && q[1].key) ? q[1].key : q[1]
    if (typeof q[1] === 'object' && q[1].no_chat) {
      userProps.no_chat = true
    }
  }
  Engage[q[0]].apply(Engage, q.splice(1))
}

// Customer Identification
const identified = !!userProps.uid
const user = {}
if (userProps.uid) {
  user.uid = userProps.uid
}
if (!userProps.uid) {
  // Is there an id in local storage?
  const u = window.localStorage.getItem('engage_user')
  if (u) {
    try {
      Object.assign(user, JSON.parse(u))
      if (user.uid) {
        userProps.uid = user.uid
      }
    } catch (e) {}
  }
}
if (!userProps.uid) {
  // Generate anonymous id
  userProps.uid = uuidv4()
  user.uid = userProps.uid
  // and store
  window.localStorage.setItem('engage_user', JSON.stringify({
    uid: userProps.uid
  }))
}

export {
  Engage,
  identified,
  userProps,
  user,
  clientId,
  // indentification,
  key
}
